import(/* webpackMode: "eager", webpackExports: ["AdScript"] */ "/vercel/path0/apps/web/app/AdScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/MaybeScrollToAnchor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/AuthDialogProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/ConfigureAmplify.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/ConfigureIntercom.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/ProgressProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/SyncPointerData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/SyncUserTimezone.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/TopLevelComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/ViewedItemsCommitter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/app/page/rootComponents/WebVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RefreshChromeExtensionSession"] */ "/vercel/path0/apps/web/app/RefreshChromeExtensionSession.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/analytics/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/CreateNewContent/CreateNewContentButtonWithHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackFlowContextProvider"] */ "/vercel/path0/apps/web/components/Feedback/FeedbackFlowContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/OpenInAppBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitialAccountSetupContextProvider"] */ "/vercel/path0/apps/web/components/Popup/InitialAccountSetupPopup/InitialAccountSetupContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/ssr/ConfigureSyncing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardPreviewContextProvider"] */ "/vercel/path0/apps/web/contexts/CardPreviewContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DialogContextProvider"] */ "/vercel/path0/apps/web/contexts/DialogContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/contexts/MultiTabsEditingWarningContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GamificationProgressingContextProvider"] */ "/vercel/path0/apps/web/features/Gamification/Progressing/GamificationProgressingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UpgradePopupContextProvider"] */ "/vercel/path0/apps/web/features/Payments/PaywallPopup/UpgradePopupContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentItemsSharingPopupContextProvider"] */ "/vercel/path0/apps/web/features/ShareSettings/hooks/useContentItemsSharingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TaggingContextProvider"] */ "/vercel/path0/apps/web/features/Tagging/hooks/useTaggingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/hooks/useAiPrivacyContextSelector.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeContextProvider"] */ "/vercel/path0/apps/web/utils/theme.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./../public/fonts/bw-modelica/BwModelica-SS01-ExtraBold.woff\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--knowt-font-name\"}],\"variableName\":\"bwModellica\"}");
;
import(/* webpackMode: "eager", webpackExports: ["PointerFlowProvider"] */ "/vercel/path0/node_modules/pointer-sdk/dist/pointer.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["S"] */ "/vercel/path0/node_modules/swr/dist/index/config-client-Bi4rgVRk.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/syncing/components/LoggingSetup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserAlertsContextProvider"] */ "/vercel/path0/packages/syncing/hooks/guidedEvents/UserAlerts.tsx");
